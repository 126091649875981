import * as React from "react";
import { CouponCodeEntryFieldsProps } from "components/flexComponents/WizardCarPWA/typings";
import { observer } from "mobx-react";
import { CarVendorCouponTypes } from "stores/wizard/config/staticConfigs/car/typings";
import { CarsVendorCodes } from "./CarsVendorCodes";
import CouponInputField from "./CouponInputField";

export const CouponCodeEntryFields = observer((props: CouponCodeEntryFieldsProps) => {
  const carCouponCodeType = props.carWizardState.carCouponCodeType;
  const carCouponInputInformationByVendor: CarVendorCouponTypes =
    props.vendor === ""
      ? props.carWizardState.config.vendors[CarsVendorCodes.NO_PREFERENCE].couponInfo
      : props.carWizardState.config.vendors[props.vendor].couponInfo;

  let inputFields;

  if (carCouponInputInformationByVendor && carCouponCodeType === "corporateCode") {
    inputFields = carCouponInputInformationByVendor.corporate;
  }
  if (carCouponInputInformationByVendor && carCouponCodeType === "specialCode") {
    inputFields = carCouponInputInformationByVendor.special;
  }

  if (inputFields) {
    const inputFieldsUsed: JSX.Element[] = [];

    for (let i = 0; i < inputFields.length; i++) {
      const inputField = inputFields[i];
      if (!inputField) {
        break;
      }
      inputFieldsUsed.push(
        <CouponInputField
          index={i}
          inputField={inputField}
          carWizardState={props.carWizardState}
          colSpan={props.colSpan}
          key={i}
        />
      );
    }

    return <>{inputFieldsUsed}</>;
  }

  return null;
});
