const timeValuesAM: string[] = [
  "1200AM",
  "1230AM",
  "0100AM",
  "0130AM",
  "0200AM",
  "0230AM",
  "0300AM",
  "0330AM",
  "0400AM",
  "0430AM",
  "0500AM",
  "0530AM",
  "0600AM",
  "0630AM",
  "0700AM",
  "0730AM",
  "0800AM",
  "0830AM",
  "0900AM",
  "0930AM",
  "1000AM",
  "1030AM",
  "1100AM",
  "1130AM",
];

const timeValuesPM: string[] = [
  "1200PM",
  "1230PM",
  "0100PM",
  "0130PM",
  "0200PM",
  "0230PM",
  "0300PM",
  "0330PM",
  "0400PM",
  "0430PM",
  "0500PM",
  "0530PM",
  "0600PM",
  "0630PM",
  "0700PM",
  "0730PM",
  "0800PM",
  "0830PM",
  "0900PM",
  "0930PM",
  "1000PM",
  "1030PM",
  "1100PM",
  "1130PM",
];

/*
  This array is intended to work for Cartrawler urls since
  the service is reading the times as 24hrs.
*/

export const timeValues24HoursFormat = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const timeValues = [...timeValuesAM, ...timeValuesPM];
