// List of brands: https://confluence.expedia.biz/display/WAYF/Cars+Storefront+Advanced+Options+-+Rental+Company+and+Discount+Code+Mapping
// Vendor codes from sf-web: https://github.expedia.biz/Brand-Expedia/storefront-web/blob/f65918f8ab031ae7001885b05c6cab66c25f8f25/src/main/webapp/WEB-INF/views/default/wizard/mercury/views/car.jsp#L244
export enum CarsVendorCodes {
  ACE_RENT_A_CAR = "AC",
  ADVANTAGE_RENT_A_CAR = "AD",
  ALAMO_RENT_A_CAR = "AL",
  AVIS = "ZI",
  BUDGET = "ZD",
  DOLLAR_RENT_A_CAR = "ZR",
  ECONOMY_RENT_A_CAR = "EY",
  ENTERPRISE = "ET",
  EUROPCAR = "EP",
  FOX_RENT_A_CAR = "FX",
  HERTZ = "ZE",
  MIDWAY_CAR_RENTAL = "MW",
  NATIONAL_CAR_RENTAL = "ZL",
  NU_CAR = "NU",
  PAYLESS = "ZA",
  ROUTES_CAR_RENTAL = "RO",
  SIXT_CAR_RENTAL = "SX",
  THRIFTY_CAR_RENTAL = "ZT",
  U_SAVE = "SV",
  SILVERCAR = "SC",
  GREEN_MOTION = "GI",
  NEXT_CAR = "NC",
  RENT_A_WRECK = "RW",
  PRICELESS = "PR",
  NO_PREFERENCE = "NO_PREFERENCE",
}
