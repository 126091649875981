import * as React from "react";

import { CarWizardState } from "stores/wizard/state";
import { useConfigOverrides } from "stores/wizard/config/overrideConfigByView/useConfigOverrides";
import { timeValues24HoursFormat } from "./timeValues";
import { isDefaultGeolocationSupported } from "components/utility/GeolocationUtil";
import { locKeysHotwire } from "components/utility/l10n";
import { timesCannonicalHotwire } from "components/utility/timeCannonical";

/**
 * Switches between the regular origin label and "Current location" based on the state.
 */
const pickUpDropOffLabel = "hotwire.wizard.car.location.pickUpAndDropOff.label";
const pickUpHyphenLabel = "hotwire.wizard.car.pickUp.withHyphen.label";
const errorDisabledGeolocation = "hotwire.wizard.error.disabledGeolocation";
const errorInvalidOrigin = "wizard.origin.warning.invalidOrigin.text";
const currentLocationToken = "typeahead.currentLocation.available.item";
export const useHotwireCarGeolocationOverrides = (carWizardState: CarWizardState, defaultToUserLocation = false) =>
  React.useEffect(() => {
    carWizardState.overrideConfig(() => {
      const { location } = carWizardState.config;
      const origin = carWizardState.location.origin.value;

      const useCurrentLocationPlaceholder =
        isDefaultGeolocationSupported() &&
        defaultToUserLocation &&
        carWizardState.isGeolocationAllowed &&
        origin === "";

      location.origin.defaultToUserLocation = useCurrentLocationPlaceholder;
      location.origin.labelToken = useCurrentLocationPlaceholder ? currentLocationToken : pickUpDropOffLabel;
      location.origin.pickUpDropOffLabelToken = useCurrentLocationPlaceholder
        ? currentLocationToken
        : pickUpDropOffLabel;
      location.origin.pickUpLocationLabelToken = useCurrentLocationPlaceholder
        ? currentLocationToken
        : pickUpHyphenLabel;
      location.origin.invalidLocationMessageKey = useCurrentLocationPlaceholder
        ? errorDisabledGeolocation
        : errorInvalidOrigin;
    });
  }, [defaultToUserLocation, carWizardState.location.origin.value, carWizardState.isGeolocationAllowed]);

export const useHotwireCarOverrides = (carWizardState: CarWizardState) =>
  useConfigOverrides(carWizardState, () => {
    const {
      config,
      config: { date, form, timeFormats, location },
    } = carWizardState;
    const baseToken = "hotwire.wizard.car";
    const baseTypeaheadToken = "hotwire.wizard.typeahead.placeholder.emptyResultPlaceholder";

    date.queryParam = {
      start: "",
      end: "",
    };
    date.daysBookableInAdvance = 330;
    date.maxDaysRange = 60;
    date.defaultOffsetDates = {
      start: 1,
      end: 2,
    };

    form.action = "https://www.hotwire.com/car-rentals/search";
    form.submitButton.locToken = "hotwire.wizard.car.submit";

    location.destination.inputName = "destination";
    location.destination.labelToken = `${baseToken}.dropOffLocation.placeholder`;
    location.destination.typeaheadEmptyResultPlaceholder = `${baseTypeaheadToken}.car`;
    location.destination.withValueLabelToken = `${baseToken}.dropOff.withHyphen.label`;
    location.destination.noValueLabelToken = `${baseToken}.dropOffLocation.placeholder`;

    location.origin.inputName = "origin";
    location.origin.labelToken = `${baseToken}.location.pickUpAndDropOff.label`;
    location.origin.pickUpDropOffLabelToken = `${baseToken}.location.pickUpAndDropOff.label`;
    location.origin.pickUpLocationLabelToken = `${baseToken}.pickUp.withHyphen.label`;
    location.origin.typeaheadEmptyResultPlaceholder = `${baseTypeaheadToken}.car`;
    location.origin.differentDropOffToken = `${baseToken}.different.dropOff.label`;
    location.origin.currentLocationInputName = "origin";
    location.origin.typeaheadCurrentLocationEnabled = true;

    timeFormats.timeFormat12Hour = true;

    config.timeValues = timeValues24HoursFormat;
    config.locKeys = locKeysHotwire;
    config.timeCannonical = timesCannonicalHotwire;
    config.isCarTrawler = false;
    config.showVendorOptions = false;
    config.includeGT = false;

    const previousDefaultShowingTime = config.defaultShowingTime;
    config.defaultShowingTime = "10:00";

    /*
     * Ensure the new config takes if the values haven't been changed, otherwise
     * it sometimes doesn't get applied due to the call to setDefaultShowingTime in the CarWizardState constructor
     */
    if (
      carWizardState.pickupTime === previousDefaultShowingTime &&
      carWizardState.returnTime === previousDefaultShowingTime
    ) {
      carWizardState.pickupTime = "";
      carWizardState.returnTime = "";
      carWizardState.setDefaultShowingTime();
    }
  });
