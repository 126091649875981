export enum CarTypes {
  ALL = "ALL",
  COMPACT = "Compact",
  ECONOMY = "Economy",
  FULLSIZE = "Fullsize",
  LUXURY = "Luxury",
  MIDSIZE = "Midsize",
  MINIVAN = "Minivan",
  OTHER = "Other",
  PICKUP = "Pickup",
  PREMIUM = "Premium",
  SPORTS_CAR = "SportsCar",
  STANDARD = "Standard",
  SUV = "Suv",
  VAN = "Van",
}
