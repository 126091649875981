export const locKeys = {
  pickUpLocationLabel: "wizard.car.pickUp.withHyphen.label",
  pickUpLocationPlaceholder: "wizard.typeahead.placeholder.title.car.pickup",
  pickUpTimeLabel: "wizard.car.time.pickUp.withHyphen.label",
  pickUpDateLabel: "wizard.car.date.pickUp.withHyphen.label",
  dropOffLocationLabel: "wizard.car.dropOff.withHyphen.label",
  filledDropOffLocationLabel: "wizard.car.dropOff.withHyphen.label",
  dropOffLocationPlaceholder: "wizard.typeahead.placeholder.title.car.dropoff",
  dropOffTimeLabel: "wizard.car.time.dropOff.withHyphen.label",
  dropOffDateLabel: "wizard.car.date.dropOff.withHyphen.label",
  brandSelectionLabel: "wizard.car.brandSelection.label",
  couponCodeSelectionLabel: "wizard.car.discountType.label",
  noCode: "wizard.car.couponCode.noCode.text",
  corporateCode: "wizard.car.couponCode.corporateCode.text",
  specialCode: "wizard.car.couponCode.specialCode.text",

  iHaveDiscount: "wizard.car.having.discount",
  aarpLabel: "wizard.car.aarp.checkbox.label",
  aarpDescription: "wizard.car.aarp.checkbox.description",
  driverAgeLabel: "wizard.car.driver.age.label",
  driverAgeRange: "wizard.car.driverBetweenAges.checkbox.v2",
  driverAgeDescription: "wizard.car.driverAge.extraFee.text.v2",
  differentDropOff: "wizard.car.different.dropOff.label",
  pickUpDropOffLabel: "wizard.car.location.pickUpAndDropOff.label",
  driversAgePlaceholder: "wizard.car.driverAge.input.placeholder",

  discountRateCode: "wizard.car.couponCode.discountRateCode.label",
  discountCouponCode: "wizard.car.couponCode.discountCouponCode.label",
  corporateDiscountNumber: "wizard.car.couponCode.corporateDiscountNumber.label",
  corporateDiscountCode: "wizard.car.couponCode.corporateDiscountCode.label",
  faststartID: "wizard.car.couponCode.faststartID.label",
  alamoInsiderNumber: "wizard.car.couponCode.alamoInsidersNumber.label",
  awdNumber: "wizard.car.couponCode.AWDNumber.label",
  wizardNumber: "wizard.car.couponCode.wizardNumber.label",
  BCDNumber: "wizard.car.couponCode.BCDNumber.label",
  fastbreakNumber: "wizard.car.couponCode.fastBreakNumber.label",
  dollarExpressNumber: "wizard.car.couponCode.dollarExpressNumber.label",
  customerNumber: "wizard.car.couponCode.customerNumber.label",
  enterprisePlusNumber: "wizard.car.couponCode.enterprisePlus#.label",
  contractNumber: "wizard.car.couponCode.contractNumber.label",
  privilegeNumber: "wizard.car.couponCode.privilegeNumber.label",
  cdp: "wizard.car.couponCode.CDP.label",
  nationalContractID: "wizard.car.couponCode.nationalContractID.label",
  emeraldClubId: "wizard.car.couponCode.emeraldClubID.label",
  corporateAccount: "wizard.car.couponCode.corporateAccount.label",
  paylessPerksNumber: "wizard.car.couponCode.paylessPerksNumber.label",
  blueChipNumber: "wizard.car.couponCode.blueChipNumber.label",

  searchButton: "wizard.submit.label",

  noPreference: "wizard.car.rentalCarCompany.label",
  aceRentACarCompany: "wizard.car.rentalCompany.aceRentACarCompany.text",
  advantageRentACarCompany: "wizard.car.rentalCompany.advantageRentACarCompany.text",
  alamoRentACarCompany: "wizard.car.rentalCompany.alamoRentACarCompany.text",
  avisCompany: "wizard.car.rentalCompany.avisCompany.text",
  budgetCompany: "wizard.car.rentalCompany.budgetCompany.text",
  dollarRentACarCompany: "wizard.car.rentalCompany.dollarRentACarCompany.text",
  economyRentACarCompany: "wizard.car.rentalCompany.economyRentACarCompany.text",
  enterpriseCompany: "wizard.car.rentalCompany.enterpriseCompany.text",
  europcarCompany: "wizard.car.rentalCompany.europcarCompany.text",
  foxRentalCarsCompany: "wizard.car.rentalCompany.foxRentalCarsCompany.text",
  hertzCompany: "wizard.car.rentalCompany.hertzCompany.text",
  midwayCarRentalCompany: "wizard.car.rentalCompany.midwayCarRentalCompany.text",
  nationalCarRentalCompany: "wizard.car.rentalCompany.nationalCarRentalCompany.text",
  nuCarCompany: "wizard.car.rentalCompany.nuCarCompany.text",
  paylessCompany: "wizard.car.rentalCompany.paylessCompany.text",
  routesCarRental: "wizard.car.rentalCompany.routesCarRental.text",
  sixtCompany: "wizard.car.rentalCompany.sixtCompany.text",
  thriftyCarRentalCompany: "wizard.car.rentalCompany.thriftyCarRentalCompany.text",
  uSaveCompany: "wizard.car.rentalCompany.uSaveCompany.text",
  silvercar: "wizard.car.rentalCompany.silvercar.text",
  greenMotionCompany: "wizard.car.rentalCompany.greenMotionCompany.text",
  nextCarCompany: "wizard.car.rentalCompany.nextCarCompany.text",
  rentAWreckCompany: "wizard.car.rentalCompany.rentAWreckCompany.text",
  pricelessCompany: "wizard.car.rentalCompany.pricelessCompany.text",

  carTabTitle: "wizard.car.tab.title",

  gtTabTitle: "wizard.gt.tab.title",
  gtSubLOBFrom: "wizard.gt.sublob.from",
  gtSubLOBTo: "wizard.gt.sublob.to",
  gtHeaderFrom: "wizard.gt.header.from",
  gtHeaderTo: "wizard.gt.header.to",
  gtProperty: "wizard.gt.hotelName.label",
  gtAirport: "wizard.gt.localtion.airport",
  gtArrivalTime: "wizard.gt.arrivalTime",
  gtArrivalDate: "wizard.gt.arrivalDate",
  gtDepartureTime: "wizard.gt.departureTime",
  gtDepartureDate: "wizard.gt.departureDate",
  gtRoundtrip: "wizard.gt.bookRoundtrip",

  compact: "wizard.carType.compact",
  economy: "wizard.carType.economy",
  fullsize: "wizard.carType.fullsize",
  luxury: "wizard.carType.luxury",
  midsize: "wizard.carType.midsize",
  minivan: "wizard.carType.minivan",
  other: "wizard.carType.other",
  pickup: "wizard.carType.pickup",
  premium: "wizard.carType.premium",
  sportsCar: "wizard.carType.sportsCar",
  standard: "wizard.carType.standard",
  SUV: "wizard.carType.suv",
  van: "wizard.carType.van",
  carType: "wizard.carType.label",
};
